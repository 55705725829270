type Args<T extends Nullable<UnknownFunction>> = T extends UnknownFunction ? Parameters<T> : []

export default function useMergedEvent<F extends (...args: any[]) => void>(...fns: Array<F | undefined>) {
  const handlers = useRef(fns.compact())

  useInsertionEffect(() => {
    handlers.current = fns.compact()
  })

  return useCallback((...args: Args<NonNullable<F>>) =>
    handlers.current.forEach(function (this: any, fn) {
      return fn.length === 1 ?
        fn.call(this, args[0]) :
        fn.apply(this, args)
    })
  , [])
}