import * as uuid_ from 'uuid'
import { getDefaultExport } from './exports.js'

const { v4, NIL } = getDefaultExport(uuid_)


const pattern = regex('i')`
^
\g<hex>{8}
-
\g<hex>{4}
-
\g<hex>{4}
-
\g<hex>{4}
-
\g<hex>{12}

(?(DEFINE)
  (?<hex> [0-9a-f])
)
`

/** Generates a v4 UUID. */
const uuid = v4 as UUID
uuid.isValid = (value: unknown): value is string => typeof value === 'string' && pattern.test(value)
uuid.nil = NIL

export default uuid

type UUID = {
  (): string

  /** Returns `true` if `value` is a valid UUID. */
  isValid: (value: unknown) => value is string

  /** Returns the UUID `'00000000-0000-0000-0000-000000000000'`. */
  nil: typeof NIL
}
